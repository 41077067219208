import { Button } from 'components/common-n4/button';
import N4Layout from 'components/layout/n4_layout';
import logger from 'lib/logger';
import { nonSbStaticProps } from 'lib/storyblok';

const log = logger({ category: '404' });

const Custom404 = () => (
  <div className="sm:px6 flex h-[40vh] min-h-[360px] flex-col items-center justify-center px-14 text-center sm:min-h-[480px] lg:px-14">
    <div className="flex max-w-[640px] flex-col items-center gap-8">
      <h1 className="text-2xl lg:text-xl">404</h1>
      <p className="text-3xl leading-[120%] -tracking-[1.92px] xs:text-4xl sm:text-6xl sm:font-medium lg:text-[64px]">Sorry, we can’t seem to find that page</p>
      <Button.LinkPrimary className="px-6 laptop:min-w-0">Go Home</Button.LinkPrimary>
    </div>
  </div>
);

Custom404.getLayout = (children, pageProps) => <N4Layout {...pageProps}>{children}</N4Layout>;

const getStaticProps = async () => {
  const slug = '/404';
  const results = await nonSbStaticProps({ slug, log });
  Object.assign(results.props, {
    is404: true,
    meta: {
      noindex: true,
      meta_title: 'Page Not Found',
    },
  });
  return results;
};

export { getStaticProps };

export default Custom404;
